import axios, { AxiosInstance } from "axios";
import { supabase } from "@/composables/supabaseClient";

import {
  ReviewFormData,
  ResponseFile,
  AuthUserData,
  Doctor,
  Stock,
  BeforeAfterPhotoFormData,
} from "@/types/models";

export class ApiService {
  private instance: AxiosInstance;
  private authUrl = "/auth";
  private reviewsUrl = "/reviews";
  token = "";

  constructor(token: string) {
    this.token = token;
    this.instance = axios.create({
      baseURL: this.baseURL,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
    });
  }

  public get baseURL(): string {
    return location.hostname !== "localhost"
      ? "https://api.kdi.center/"
      : "https://api.kdi.center/";
  }

  public get uploadUrl() {
    return `${this.baseURL}upload`;
  }

  // Auth =================================================================================
  auth(data: AuthUserData) {
    return this.instance.post(this.authUrl, data);
  }

  // Reviews ==============================================================================
  postReviews(data: ReviewFormData) {
    return this.instance.post(this.reviewsUrl, data);
  }
  getReviews() {
    return this.instance.get(this.reviewsUrl);
  }
  getReviewById(id: string) {
    return this.instance.get(`${this.reviewsUrl}/${id}`);
  }
  deleteReviewById(id: string) {
    return this.instance.delete(`${this.reviewsUrl}/${id}`);
  }
  putReviewsById(data: ReviewFormData, id: string) {
    return this.instance.put(`${this.reviewsUrl}/${id}`, data);
  }

  // Doctors ==============================================================================
  postDoctor(data: Doctor) {
    return this.instance.post("/doctors", data);
  }
  getDoctors() {
    return this.instance.get("/doctors");
  }
  getDoctorById(id: string) {
    return this.instance.get(`/doctors/${id}`);
  }
  deleteDoctorById(id: string) {
    return this.instance.delete(`/doctors/${id}`);
  }
  putDoctorById(data: Doctor, id: string) {
    return this.instance.put(`/doctors/${id}`, data);
  }

  // Stock ================================================================================
  getStock() {
    return supabase
      .from("sales")
      .select()
      .order("createdAt", { ascending: false });
  }
  postStock(data: Stock) {
    return supabase.from("sales").insert([data]);
  }
  getStockById(id: string) {
    return supabase.from("sales").select().eq("id", id);
  }
  deleteStockById(id: string) {
    return supabase.from("sales").delete().eq("id", id);
  }
  putStockById(data: Stock, id: string) {
    return supabase.from("sales").update(data).eq("id", id);
  }

  loadStockFile(file: any) {
    return supabase.storage.from("sales").upload(`${Date.now()}`, file);
  }

  deleteStockFile(file: string) {
    return supabase.storage.from("sales").remove([file]);
  }

  // BeforeAfterPhoto ================================================================================
  getBeforeAfterPhoto() {
    return this.instance.get("/before-after-photo");
  }
  postBeforeAfterPhoto(data: BeforeAfterPhotoFormData) {
    return this.instance.post("/before-after-photo", data);
  }
  getBeforeAfterPhotoById(id: string) {
    return this.instance.get(`/before-after-photo/${id}`);
  }
  deleteBeforeAfterPhotoById(id: string) {
    return this.instance.delete(`/before-after-photo/${id}`);
  }
  putBeforeAfterPhotoById(data: BeforeAfterPhotoFormData, id: string) {
    return this.instance.put(`/before-after-photo/${id}`, data);
  }

  // Files ================================================================================
  deleteFile(data: ResponseFile) {
    return this.instance.post("/delete-file", data);
  }

  // Token ================================================================================
  tokenVerification() {
    return this.instance.get("/token-verification");
  }

  // Errors ================================================================================
  errorProcessing(errMessage: string) {
    console.log(errMessage);
  }
}
