import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { ApiService } from "@/services/api/index";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    meta: {
      auth: true,
      layout: "MainLayout",
    },
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/reviews",
    name: "reviews",
    meta: {
      auth: true,
      layout: "MainLayout",
    },
    component: () => import("@/views/ReviewsView.vue"),
  },
  {
    path: "/review/:id?",
    name: "review",
    meta: {
      auth: true,
      layout: "MainLayout",
    },
    component: () => import("@/views/ReviewsFormView.vue"),
  },

  {
    path: "/before-after-photos",
    name: "BeforeAfterPhotos",
    meta: {
      auth: true,
      layout: "MainLayout",
    },
    component: () => import("@/views/BeforeAfterPhotoView.vue"),
  },
  {
    path: "/articles",
    name: "Articles",
    meta: {
      auth: true,
      layout: "MainLayout",
    },
    component: () => import("@/views/ArticlesPage.vue"),
  },
  {
    path: "/article/:id?",
    name: "Article",
    meta: {
      auth: true,
      layout: "MainLayout",
    },
    component: () => import("@/views/ArticleForm.vue"),
  },
  {
    path: "/article-categories",
    name: "ArticleCategories",
    meta: {
      auth: true,
      layout: "MainLayout",
    },
    component: () => import("@/views/ArticleCategories.vue"),
  },
  {
    path: "/article-category/:id?",
    name: "ArticleCategory",
    meta: {
      auth: true,
      layout: "MainLayout",
    },
    component: () => import("@/views/ArticleCategoryForm.vue"),
  },
  {
    path: "/before-after-photo/:id?",
    name: "before-after-photo",
    meta: {
      auth: true,
      layout: "MainLayout",
    },
    component: () => import("@/views/BeforeAfterPhotoFormView.vue"),
  },
  {
    path: "/doctors",
    name: "doctors",
    meta: {
      auth: true,
      layout: "MainLayout",
    },
    component: () => import("@/views/DoctorsView.vue"),
  },
  {
    path: "/doctor/:id?",
    name: "doctor",
    meta: {
      auth: true,
      layout: "MainLayout",
    },
    component: () => import("@/views/DoctorsFormView.vue"),
  },
  {
    path: "/stock/:id?",
    name: "stock",
    meta: {
      auth: true,
      layout: "MainLayout",
    },
    component: () => import("@/views/StockFormView.vue"),
  },
  {
    path: "/stock-list",
    name: "stock-list",
    meta: {
      auth: true,
      layout: "MainLayout",
    },
    component: () => import("@/views/StockView.vue"),
  },
  {
    path: "/price-categories",
    name: "PriceCategories",
    meta: {
      auth: true,
      layout: "MainLayout",
    },
    component: () => import("@/views/PriceCategories.vue"),
  },
  {
    path: "/price-category/:id?",
    name: "PriceCategory",
    meta: {
      auth: true,
      layout: "MainLayout",
    },
    component: () => import("@/views/PriceCategory.vue"),
  },
  {
    path: "/auth",
    name: "auth",
    meta: {
      auth: false,
      layout: "EmptyLayout",
    },
    component: () => import("../views/AuthPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requireAuth = to.matched.some((record) => record.meta.auth);
  if (requireAuth) {
    if (localStorage.token) {
      const apiService = new ApiService(localStorage.token);

      await apiService
        .tokenVerification()
        .then(() => {
          next();
        })
        .catch(() => {
          next("/auth");
        });
    } else {
      next("/auth");
    }
  } else {
    next();
  }
});

export default router;
